/* Mobile verison */
header {
  position: relative;
  background-color: #94B9AF;
  border-bottom: 1px solid #000;
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.25);
}

.logo img {
  height: 60px;
  padding: 1em;
}

.bars {
  position: relative;
  float: right;
  padding: 1em;
  margin: 1em 1.5em 0 0;
}
  .bars span {
    position: absolute;
    background: #000;
    display: block;
    border-radius: 3em;
    width: 100%;
    height: 6px;
    right: 0;
  }
    .bars span:nth-child(1) {
      top: 0;
      width: 60%;
    }
    .bars span:nth-child(2) {
      top: 12px;
      width: 80%;
    }
    .bars span:nth-child(3) {
      top: 24px;
    }

.closed {
  display: none;
  transition: .5 ease-in-out;
}

.open {
  animation: slideDown 1s ease-in-out;
  /* height: 100vh; */
}

.desktop-menu {
  display: none;
}

.mobile-menu {
  padding-left: 1em;
}
  .mobile-menu li {
    list-style: none;
    padding-bottom: 1em;
  }


/* Desktop version */
@media (min-width: 640px) {

  header {
    position: absolute;
    top: 0;
    right: 0;
      box-shadow: none;
  }

  .header-inner {
    padding: 3em 7em 3em 2em;
  }
  
  .logo img {
    height: 90px;
  }
  
  .bars {
    display: none;
  }

  .mobile-menu {
    display: none;
  }

  .desktop-menu {
    display: block;
    list-style: none;
    padding: 2em;
    padding-bottom: 0;
  }
    .desktop-menu li {
      padding-bottom: 3em;
      transition: .5s ease-in-out;
    }
      .desktop-menu li:last-of-type {
        padding-bottom: 0;
      }
        .desktop-menu li a {
          position: relative;
          font-size: 24px;
          color: #000;
        }
          .desktop-menu li a:after {
            content: '';
            position: absolute;
            top: .7em;
            left: 0;
            height: 3px;
            width: 0;
            background-color: #000;
            transition: .3s ease-in-out;
          }

        .desktop-menu li a:hover:after {
          width: 100%;
        }
}

@media (min-width: 640px) and (max-width: 1440px) {

  .logo img {
    height: 70px;
  }

  .desktop-menu li a {
    font-size: 18px;
  }

}


/* KEYFRAMES */

@keyframes slideDown {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}