* {
  box-sizing: border-box;
}  
  body {
  position: relative;
  padding: 0;
  margin: 0 auto;
  font-family: sofia-pro, sans-serif;
}

a, a svg {
  font-weight: bold;
  text-decoration: none;
  color: #000;
  transition: .5s ease-in-out;
}
  a:hover, a svg:hover {
    color: #fff;
  }

h1 {
  font-size: 34px;
  line-height: 40px;
  margin: 0;
  padding: 0;
  transition: .3s ease-in-out;
}

h2 {
  font-size: 24px;
  line-height: 30px;
  margin: 0;
  padding: 0;
  transition: .3s ease-in-out;
}

h3 {
  font-size: 22px;
}

section {
  padding: 1em;
}


@media (min-width: 800px) {
  h1 {
    font-size: 60px;
    line-height: 72px;
  }

  h2 {
    font-size: 42px;
    line-height: 50px;
  }
}

@media (min-width: 640px) {
  h3 {
    font-size: 32px;
  }

  section {
    padding: 3em 5em;
  }
}


@media (min-width: 640px) and (max-width: 799px) {
  h1 {
    font-size: 42px;
    line-height: 50px;
  }
  
  h2 {
    font-size: 34px;
    line-height: 40px;
  }
}