.work {
  background-color: #FEFAE0;
}

.work .work-inner {
  margin: auto;
  max-width: 1440px;
}

.work .inner-content {
  display: flex;
  flex-wrap: wrap;
  width: calc(100%);
  margin: 0 0 2em 0;
  padding: 0;
  list-style: none;
}
  .work .inner-content li {
    position: relative;
    background-color: rgba(255,255,255, .8);
    width: calc(100%);
    height: 150px;
    margin-bottom: 1em;
    /* border: 1px solid #ccc; */
    border: 1px solid transparent;
    transition: .3s ease-in-out;
  }

    .work .inner-content li a {
      width: 100%;
      height: 100%;
    }

  .work .inner-content li .work-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 1em;
  }
    .work .inner-content li .work-info h4 {
      color: #000;
      font-size: 18px;
      margin: 0;
    }
    .work .inner-content li .work-info img {
      width: auto;
      height: 60px;
      opacity: 1;
      transition: .3s ease-in-out;
      margin-bottom: 1em;
    }
    /* .work .inner-content li .work-info p {
      color: #000;
      font-size: 12px;
    } */
    .work .inner-content li .work-info ul {
      opacity: 1;
      text-align: center;
      width: 100%;
      margin: 0;
      padding: 0;
    }
      .work .inner-content li .work-info ul li {
        display: inline;
        list-style: none;
        color: #000;
        border: none;
        margin: 0;
      }


/* Media Queries */

@media (min-width: 800px) {
  .work .inner-content li {
    background-color: transparent;
    height: 240px;
  }

  .work .inner-content li .work-info {
    padding: 2em;
  }

  .work .inner-content li .work-info h4 {
    font-size: 24px;
    opacity: 0;
  }

  .work .inner-content li .work-info ul {
    opacity: 0;
  }
  /* .work .inner-content li .work-info p {
    font-size: 1em;
    opacity: 0;
  } */

  .work .inner-content li .work-info img {
    height: 100px;
  }

  /* Hover stuff */
  .work .inner-content li:hover {
    background-color: rgba(255,255,255, .8);
    border: 1px solid #000;
    border-radius: 2px;
  }
  .work .inner-content li:hover a .work-info h4,
  .work .inner-content li:hover a .work-info ul,
  .work .inner-content li:hover a .work-info p {
    opacity: 1;
  }
} 

@media (min-width: 640px) { 
  .work .inner-content li {
    width: calc(100% * .32);
    height: auto;
    margin-right: 2%;
    margin-bottom: 2em;
  }
    .work .inner-content li:nth-child(3n) {
      margin-right: 0;
    }

}