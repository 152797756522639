.about {
  background-color: #94B9AF;
}

.about .about-inner {
  margin: auto;
  max-width: 1440px;
}

.about .inner-content {
  display: flex;
  flex-wrap: wrap;
  width: calc(100%);
  margin-bottom: 2em;
}
  .about-text,
  .about-img {
    width: calc(100%);
  }

  .about-text {
    margin-bottom: 3em;
  }

  .about-img img {
    width: calc(100%);
  }


@media (min-width: 800px) {
  .about .inner-content {
    margin-bottom: 0em;
  }
    .about-text {
      margin-bottom: 4em;
    }

  .about-img {
    filter: blur(10px);
    transition: filter .3s ease-in-out;
  }


  /* Hover stuff */
  .about-img:hover {
    filter: blur(0px);
    cursor: crosshair;
  }
}

@media (min-width: 640px) {
  .about-text,
  .about-img {
    width: calc(100% / 2);
  }

  .about-text {
    padding-right: 2em;
    box-sizing: border-box;
  }
}