.footer {
  position: relative;
  background-color: #DDA3B2;
}
  .footer .text {
    color: #000;
    font-size: 16px;
    text-align: center;
    padding: 1em 0;
  }

.footer button {
  display: none;
}


@media (min-width: 640px) {
  .footer .text {
    font-size: 20px;
    padding: 2em 0;
  }

  .footer button {
    display: block;
    position: absolute;
    top: 2em;
    right: 3em;
    background-color: transparent;
    border: none;
    font-size: 1em;
  }
}