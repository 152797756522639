.btn {
  text-align: center;
  border: 1px solid #000;
  margin: 0 auto 1em;
  padding: 1em;
  width: 120px;
  cursor: pointer;
  transition: .3s ease-in-out;
}
  .btn:hover,
  .btn:focus {
    color: #fff;
    font-weight: bold;
    box-shadow: inset 10em 0 0 0 black;
  }


@media (min-width: 640px) {
  .btn {
    margin: 0 auto;
  }
}