.hero-outer {
  background: url('/images/header_img.jpg');
  background-size: cover;
  background-position: 20%;
  background-repeat: no-repeat;
  height: 400px;
}

section.hero h1,
section.hero h2 {
  color: #fff;
}

section.hero h2 {
  font-weight: normal;
}

@media (min-width: 640px) {
  .hero-outer {
    background-position: center center;
    height: 100vh;
    max-height: 1040px;
  }
}

@media (min-width: 640px) and (max-width: 799px) {
  .hero-outer {
    height: 600px;
  }
}