.contact {
  background-color: #DDA3B2;
}

.contact .contact-inner {
  margin: auto;
  max-width: 1440px;
}

.contact .inner-content {
  width: 100%;
  margin-bottom: 2em;
}

  .contact .inner-content .contact-text {
    margin-bottom: 2em;
  }

.inner-content .social-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  margin: 0;
  padding: 0;
}
  .inner-content .social-list li {
    margin-right: 2.5em;
  }
    .inner-content .social-list li a {
      color: #000;
      font-size: 1.5em;
    }


@media (min-width: 800px) {
  .contact .inner-content {
    width: calc(100% / 2);
    margin-bottom: 0em;
  }
    .contact .inner-content .contact-text {
      margin-bottom: 4em;
    }

  .inner-content .social-list li {
    margin-right: 3em;
  }
    .inner-content .social-list li a {
      font-size: 2em;
    }
}